<template>
  <div class="reserved-area-page add-workout">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <FormulateForm v-model="values" @submit="addWorkout">
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Descrição</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="select" name="type" v-model="workoutType" validation="required" label="Tipo Treino" :options="{1: 'Workout', 2: 'Workshop'}" />
                    </div>
                  </div>
                  <div v-if="workoutType === '2'" class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="text" name="workout-theme" v-model="workoutTheme" validation="required" placeholder="Insira o tema do Workshop" label="Tema do Workshop" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="text" name="workout-title" v-model="workoutTitle" validation="required" placeholder="Insira o titulo do treino" label="Titulo Treino" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="textarea" rows="9" name="workout-description" v-model="workoutDescription" validation="required" placeholder="" label="Sobre" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field input-file">
                      <label class="d-flex flex-column">
                        <FormulateInput type="image" name="workout-image" v-model="workoutImage" validation="mime:image/jpeg,image/png,image/gif"/>
                        <div class="input-file-content d-flex justify-content-between align-items-center">
                          <p class="input-file-title">Adicionar Imagem</p>
                          <button type="button" class="input-file-btn">
                            <plusIcon/>
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Video</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field input-file video-file">
                      <label class="d-flex flex-column">
                        <FormulateInput type="file" name="workout-video" v-model="workoutVideo" validation="mime:video/mp4,video/webm" />
                        <div class="input-file-content d-flex justify-content-between align-items-center">
                          <p class="input-file-title">Adicionar Video</p>
                          <button type="button" class="input-file-btn">
                            <plusIcon/>
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Informação</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="select" name="duration" v-model="workoutDuration" validation="required" label="Duração" :options="{1: '~15min', 2: '~30min', 3: '~45min', 4: '~60min', 5: '~90min'}" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="select" name="level" v-model="workoutLevel" validation="required" label="Nível" :options="{1: 'Fácil', 2: 'Médio', 3: 'Difícil'}" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Treinar Por</p>
              <div class="reserved-area-form-group">
                <div class="reserved-area-list-wrapper">
                  <button type="button" class="reserved-area-list-btn d-flex justify-content-between align-items-center" @click="openAddObjectiveModal">
                    <p class="reserved-area-list-btn-title">Objetivo</p>
                    <div class="reserved-area-list-btn-icon d-flex justify-content-center align-items-center">
                      <plusIcon/>
                    </div>
                  </button>
                  <div class="reserved-area-list clearfix">
                    <div class="reserved-area-list-objective-item" v-for="(item,index) in selectedObjectives" :key="index" :id="item.id">
                      <p class="reserved-area-list-objective-item-title">{{ item.title }}</p>
                      <button type="button" class="reserved-area-list-objective-item-icon" @click="removeObjective">
                        <closeIcon/>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="reserved-area-list-wrapper">
                  <button type="button" class="reserved-area-list-btn d-flex justify-content-between align-items-center" @click="openAddSportModal">
                    <p class="reserved-area-list-btn-title">Modalidade</p>
                    <div class="reserved-area-list-btn-icon d-flex justify-content-center align-items-center">
                      <plusIcon/>
                    </div>
                  </button>
                  <div class="reserved-area-list clearfix">
                    <div class="reserved-area-list-objective-item" v-for="(item,index) in selectedSports" :key="index" :id="item.id">
                      <p class="reserved-area-list-objective-item-title">{{ item.title }}</p>
                      <button type="button" class="reserved-area-list-objective-item-icon" @click="removeSport">
                        <closeIcon/>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="reserved-area-list-wrapper">
                  <button type="button" class="reserved-area-list-btn d-flex justify-content-between align-items-center" @click="openAddMuscleModal">
                    <p class="reserved-area-list-btn-title">Grupo Muscular</p>
                    <div class="reserved-area-list-btn-icon d-flex justify-content-center align-items-center">
                      <plusIcon/>
                    </div>
                  </button>
                  <div class="reserved-area-list clearfix">
                    <div class="reserved-area-list-objective-item" v-for="(item,index) in selectedMuscles" :key="index" :id="item.id">
                      <p class="reserved-area-list-objective-item-title">{{ item.title }}</p>
                      <button type="button" class="reserved-area-list-objective-item-icon" @click="removeMuscle">
                        <closeIcon/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Execícios Complementares</p>
              <div class="reserved-area-form-group">
                <div class="reserved-area-list-wrapper">
                  <button type="button" class="reserved-area-list-btn d-flex justify-content-between align-items-center" @click="openAddExercisesModal">
                    <p class="reserved-area-list-btn-title">Adicionar Exercícios</p>
                    <div class="reserved-area-list-btn-icon d-flex justify-content-center align-items-center">
                      <plusIcon/>
                    </div>
                  </button>
                  <div class="reserved-area-list clearfix">
                    <div class="reserved-area-workout-item" v-for="(item,index) in selectedExercises" :key="index" :id="item.id">
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="reserved-area-workout-info-wrapper d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img :src="item.media.general[0].icon" alt="" v-if="item.media.general">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">{{ item.title }}</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>{{ item.duration }}</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>{{ item.level }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="button" class="reserved-area-workout-btn d-flex justify-content-center align-items-center" @click="removeExercise">
                          <trashIcon/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Equipamento</p>
              <div class="reserved-area-form-group">
                <div class="reserved-area-list-wrapper">
                  <button type="button" class="reserved-area-list-btn d-flex justify-content-between align-items-center" @click="openAddEquipmentModal">
                    <p class="reserved-area-list-btn-title">Adicionar Equipamento</p>
                    <div class="reserved-area-list-btn-icon d-flex justify-content-center align-items-center">
                      <plusIcon/>
                    </div>
                  </button>
                  <div class="reserved-area-list clearfix">
                    <div class="reserved-area-workout-item" v-for="(item,index) in selectedEquipment" :key="index" :id="item.id">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="reserved-area-workout-image" v-if="!!item.media.general">
                            <img :src="item.media.general[0].icon" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">{{ item.title }}</p>
                          </div>
                        </div>
                        <button type="button" class="reserved-area-workout-btn d-flex justify-content-center align-items-center" @click="removeEquipment">
                          <trashIcon/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>

    <addEquipmentModal/>
    <addSportModal/>
    <addMuscleModal/>
    <addExerciseModal/>
    <addObjectiveModal/>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import plusIcon from "@/components/svg/plus.vue";
import closeIcon from "@/components/svg/close.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import trashIcon from "@/components/svg/trash.vue";

import addEquipmentModal from "@/components/modals/add-equipment-modal.vue";
import addSportModal from "@/components/modals/add-sport-modal.vue";
import addMuscleModal from "@/components/modals/add-muscle-modal.vue";
import addExerciseModal from "@/components/modals/add-exercises-modal.vue";
import addObjectiveModal from "@/components/modals/add-objective-modal.vue";
import axios from "axios";
import qs from "qs";

import {EventBus} from "@/event-bus";

export default {
  name: "reserved-area-creator-add-workout",
  data() {
    return {
      values: {},
      workoutType: 1,
      workoutTitle: '',
      workoutTheme: '',
      workoutDescription: '',
      workoutImage: '',
      workoutVideo: '',
      workoutDuration: '',
      workoutIntensity: '',
      workoutLevel: '',
      selectedEquipment: [],
      selectedEquipmentIDs: [],
      selectedMuscles: [],
      selectedMusclesIDs: [],
      selectedSports: [],
      selectedSportsIDs: [],
      selectedExercises: [],
      selectedExercisesIDs: [],
      selectedObjectives: [],
      selectedObjectivesIDs: [],
    };
  },
  components: {
    reservedAreaMenu,
    plusIcon,
    closeIcon,
    clock,
    level,
    fire,
    trashIcon,
    addExerciseModal,
    addEquipmentModal,
    addSportModal,
    addMuscleModal,
    addObjectiveModal
  },
  methods: {
    openAddExercisesModal() {
      let element = document.getElementsByClassName("add-exercises-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    openAddObjectiveModal() {
      let element = document.getElementsByClassName("add-objective-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    openAddEquipmentModal() {
      let element = document.getElementsByClassName("add-equipment-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    openAddSportModal() {
      let element = document.getElementsByClassName("add-sport-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    openAddMuscleModal() {
      let element = document.getElementsByClassName("add-muscle-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    addWorkout() {
      let authKey = window.$cookies.get("authKey");

      let video = URL.createObjectURL(this.workoutVideo.files[0].file);

      let formData = new FormData();
      formData.append('workoutType', this.workoutType);
      formData.append('title', this.workoutTitle);
      formData.append('theme', this.workoutTheme);
      formData.append('description', this.workoutDescription);
      formData.append('image', this.workoutImage.files[0].file);
      formData.append('video', this.workoutVideo.files[0].file);
      formData.append('duration', this.workoutDuration);
      formData.append('intensity', this.workoutIntensity);
      formData.append('level', this.workoutLevel);
      formData.append('objectives', this.selectedObjectivesIDs);
      formData.append('sports', this.selectedSportsIDs);
      formData.append('muscles', this.selectedMusclesIDs);
      formData.append('exercises', this.selectedExercisesIDs);
      formData.append('equipments', this.selectedEquipmentIDs);

      axios
          .post(process.env.VUE_APP_API_URL + 'products', formData,{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.$router.push({ name: 'reserved-area-creator-dashboard' })
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
    removeObjective(event) {
      let element = event.target.closest('.reserved-area-list-objective-item');
      let elementID = element.getAttribute('id');

      let removeIndex = this.selectedObjectives.findIndex( item => item.id === elementID );
      this.selectedObjectives.splice( removeIndex, 1 );

      EventBus.$emit('bus-removeObjective', elementID);
    },
    removeSport(event) {
      let element = event.target.closest('.reserved-area-list-objective-item');
      let elementID = element.getAttribute('id');

      let removeIndex = this.selectedSports.findIndex( item => item.id === elementID );
      this.selectedSports.splice( removeIndex, 1 );

      EventBus.$emit('bus-removeSport', elementID);
    },
    removeMuscle(event) {
      let element = event.target.closest('.reserved-area-list-objective-item');
      let elementID = element.getAttribute('id');

      let removeIndex = this.selectedMuscles.findIndex( item => item.id === elementID );
      this.selectedMuscles.splice( removeIndex, 1 );

      EventBus.$emit('bus-removeMuscle', elementID);
    },
    removeExercise(event) {
      let element = event.target.closest('.reserved-area-workout-item');
      let elementID = element.getAttribute('id');

      let removeIndex = this.selectedExercises.findIndex( item => item.id === elementID );
      this.selectedExercises.splice( removeIndex, 1 );

      EventBus.$emit('bus-removeExercise', elementID);
    },
    removeEquipment(event) {
      let element = event.target.closest('.reserved-area-workout-item');
      let elementID = element.getAttribute('id');

      let removeIndex = this.selectedEquipment.findIndex( item => item.id === elementID );
      this.selectedEquipment.splice( removeIndex, 1 );

      EventBus.$emit('bus-removeEquipment', elementID);
    }
  },
  created() {
    let self = this;

    EventBus.$on('bus-selectedEquipment', function(items, itemsIDs) {
      self.selectedEquipment = items;
      self.selectedEquipmentIDs = itemsIDs;
    });

    EventBus.$on('bus-selectedMuscles', function(items, itemsIDs) {
      self.selectedMuscles = items;
      self.selectedMusclesIDs = itemsIDs;
    });

    EventBus.$on('bus-selectedSports', function(items, itemsIDs) {
      self.selectedSports = items;
      self.selectedSportsIDs = itemsIDs;
    });

    EventBus.$on('bus-selectedExercises', function(items, itemsIDs) {
      self.selectedExercises = items;
      self.selectedExercisesIDs = itemsIDs;
    });

    EventBus.$on('bus-selectedObjectives', function(items, itemsIDs) {
      self.selectedObjectives = items;
      self.selectedObjectivesIDs = itemsIDs;
    });
  }
}
</script>