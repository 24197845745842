<template>
  <div class="box-modal-wrapper add-exercises-modal white-bg reserved-area-modal">
    <div class="container">
      <div class="box-modal-header with-bg">
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="close-modal-btn" @click="closeAddExercisesModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-title">
            <p>ADICIONAR EXERCICIOS</p>
          </div>
        </div>
      </div>
      <div class="box-modal-body">
        <FormulateForm class="d-flex justify-content-between flex-column" v-model="values" @submit="addExercises">
          <div class="reserved-area-form-group-wrapper">
            <div class="reserved-area-form-group">
              <sliderFilter/>
              <div class="reserved-area-workout-list">
                <div class="reserved-area-exercise-item" v-for="(item,index) in items" :key="index" :class="[ item.media.general ? '' : 'no-image']">
                  <label>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="reserved-area-workout-info-wrapper d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img :src="item.media.general[0].image" alt="" v-if="item.media.general">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">{{ item.title }}</p>
                          <div class="workout-item-tags">
                            <div v-if="item.duration" class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <clock/>
                              </div>
                              <p>{{ item.duration }}</p>
                            </div>
                            <div v-if="item.level" class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <fire/>
                              </div>
                              <p>{{ item.level }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="reserved-area-exercise-item-checkbox">
                        <input type="checkbox" :value="item.id" v-model="selectedExercisesIDs">
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
            <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Adicionar Exercícios</button>
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import sliderFilter from "@/components/slider-filter.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import closeIcon from "@/components/svg/close.vue";
import axios from "axios";
import {EventBus} from "@/event-bus";

export default {
  name: "add-exercises-modal",
  data() {
    return {
      lang: this.$root.siteLang,
      items: [],
      values: {},
      selectedExercisesIDs: [],
      selectedExercises: [],
    }
  },
  components: {
    sliderFilter,
    clock,
    level,
    fire,
    closeIcon
  },
  methods: {
    closeAddExercisesModal() {
      document.getElementsByClassName("add-exercises-modal")[0].classList.remove('open');
    },
    addExercises() {
      this.selectedExercises = this.items.filter((item) => this.selectedExercisesIDs.includes(item.id));

      EventBus.$emit('bus-selectedExercises', this.selectedExercises, this.selectedExercisesIDs);

      this.closeAddExercisesModal();
    }
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");
    axios
        .get(process.env.VUE_APP_API_URL + 'products?id_category=3&with_category_title=0&lang=' + this.lang, {
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.items = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {
    });
  },
  created() {
    let self = this;

    EventBus.$on('bus-removeExercise', function (id) {
      let elementID = id;
      let removeIndex = self.selectedExercisesIDs.findIndex(item => item === elementID);
      self.selectedExercisesIDs.splice(removeIndex, 1);
    });
  }
}
</script>